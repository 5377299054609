.logoforVender{
    width: 197px;
    height: 53px;
    /* margin-left: 27px; */
}
.nav{
    height: 80px;; 
    background-color: rgba(4, 9, 44, 1);
    color : #fff;
  }
.userinfo{
    font-family: "Poppins";
    font-size: 14px;
}
#SmallScreenDevice{
    display: none;
  }
@media screen and (max-width: 580px){
    #largesScreenDevice{
        display: none !important;
      }
      #SmallScreenDevice{
       display: block;
      } 
    .logoforVender{
        width: 167px;
        height: 45px;
        margin-left: 20px;
    }
    .nav{
        height: 56px; 
        background-color: rgba(4, 9, 44, 1);
        color : #fff;
        padding: 0rem 1rem;
      }
    .userinfo{
        font-family: "Poppins";
        font-size: 12px !important;
    }
}