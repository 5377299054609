.container-main {
  margin: 0px 134px;
}

.mainheading {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.tabheading {
  font-family: "Poppins";
  font-size: 24px;
  width: 360px !important;
  height: 80px !important;
  text-align: start;
  margin: 0px 10px !important;
  border: none !important;
  border-radius: 0.2rem;
  background-color: #e7e9eb !important;
  color: #CFD6DC;
}

.isActive {
  font-family: "Poppins";
  font-size: 24px;
  width: 360px !important;
  height: 80px !important; 
  text-align: start;
  margin: 0px 10px !important;
  color: #ffff !important;
  border: none!important;
  border-radius: 0.2rem;
  background: #2C9CF0 !important;
}


@media screen and (max-width: 1430px) {
  .tabheading {
    font-family: "Poppins";
    width: 350px;
    height: 90px;
    margin: 0px 5px;
    border: 2px solid rgb(107, 106, 106);
    background-color: #fff;
    font-weight: bold;

    color: rgb(107, 106, 106);
  }

  .isActive {
    font-family: "Poppins";
    width: 350px;
    height: 90px;
    margin: 0px 5px;
    color: rgb(28, 184, 246);
    border: 1px solid rgb(107, 106, 106);
    background-color: #fff;
    font-weight: bold;

  }
}

@media screen and (max-width: 1120px) {
  .tabheading {
    font-family: "Poppins";
    width: 310px;
    height: 90px;
    margin: 0px 7px;
    border: 2px solid rgb(107, 106, 106);
    background-color: #fff;
    font-weight: bold;

    color: rgb(107, 106, 106);
  }

  .isActive {
    font-family: "Poppins";
    width: 310px;
    height: 90px;
    margin: 0px 7px;
    color: rgb(28, 184, 246);
    border: 1px solid rgb(107, 106, 106);
    background-color: #fff;
    font-weight: bold;

  }
}

@media screen and (max-width: 1020px) {
  .tabheading {
    font-family: "Poppins";
    width: 270px;
    height: 85px;
    margin: 0px 7px;
    border: 2px solid rgb(107, 106, 106);
    background-color: #fff;
    font-weight: bold;

    color: rgb(107, 106, 106);
  }

  .isActive {
    font-family: "Poppins";
    width: 270px;
    height: 85px;
    margin: 0px 7px;
    color: rgb(28, 184, 246);
    border: 1px solid rgb(107, 106, 106);
    background-color: #fff;
    font-weight: bold;

  }
}

@media screen and (max-width: 885px) {
  .mainheading {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 35px;
  }

  .tabheading {
    font-size: 12px !important;
    font-family: "Poppins";
    width: 250px;
    height: 55px;
    margin: 0px 7px;
    border: 2px solid rgb(107, 106, 106);
    background-color: #fff;
    font-weight: bold;

    color: rgb(107, 106, 106);
  }

  .isActive {
    font-size: 12px !important;
    font-family: "Poppins";
    width: 250px;
    height: 55px;
    margin: 0px 7px;
    color: rgb(28, 184, 246);
    border: 1px solid rgb(107, 106, 106);
    background-color: #fff;
    font-weight: bold;

  }
}

@media screen and (max-width: 750px) {
  .container-main {
    margin: 0px 98px;
  }

  .mainheading {
    font-family: "Poppins";
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .tabheading {
    font-size: 10px !important;
    font-family: "Poppins";
    width: 158px;
    height: 35px;
    margin: 0px 7px;
    border: 2px solid rgb(107, 106, 106);
    background-color: #fff;
    font-weight: bold;

    color: rgb(107, 106, 106);
  }

  .isActive {
    font-size: 10px !important;
    font-family: "Poppins";
    width: 158px;
    height: 35px;
    margin: 0px 7px;
    color: rgb(28, 184, 246);
    border: 1px solid rgb(107, 106, 106);
    background-color: #fff;
    font-weight: bold;

  }
}

@media screen and (max-width: 580px) {
  .container-main {
    margin: 0px 5px;
  }

  .mainheading {
    display: none;
  }

  .tabheading {
    display: none;
  }

  .isActive {
    display: block;
    margin-top: 23px !important;
    font-size: 24px !important;
    font-family: "Poppins";
    width: 98%;
    height: 35px;
    color: rgb(28, 184, 246);
    border: 1px solid rgb(107, 106, 106);
    background-color: #fff;
  }
  .isBackActive {
    width: 84% !important;
  }

  .tabname{
    font-size: 9px !important;
  }
  .back-tab{
    position: relative;
    top: 4.3rem;
    z-index: -1;
  }
  .addon-tab{
    position: relative;
    top: -8.5rem;
  }
}