.main-heading {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
.sub-heading{
    font-size: 16px;
    font-family: "Poppins";
    color: #6A6A6A;
}