body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

/* Hide the spinners in WebKit browsers */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fontstylePoppin {
  font-family: "Poppins";
}

code {
  font-family: "Poppins", sans-serif;
}

.edit-btn {
  background: #2C9CF0 !important;
  font-size: 14px !important;
  font-family: "Poppins";
  border: none;
  border-radius: 1rem;
  color: #ffff !important;
  padding: 0.2rem 1rem;
  margin-top: 0.2rem;
}

/* Common Css */
.link {
  color: white;
  text-decoration: none;
}

button {
  border: none;
}

@media screen and (max-width :578px) {
  #hideforSmallDevices {
    display: none !important;
  }
}

/* AddEventDetails Css */
.addeventdetailDiv {
  width: 80%;
  margin: auto;
  margin-bottom: 10%;
}

.subnavbar {
  width: 70%;
  margin: auto;
  margin-top: 32px;
}

.subnavitem {
  font-family: "Poppins";
  font-size: 23px;
  color: #808080;
}

.isActiveForm {
  color: rgb(28, 184, 246);
}

.defalut {
  color: #808080;
}

.poppins20 {
  font-family: "Poppins";
  font-size: 18px;
}

.eveVisibilitybtn {
  width: 20% !important;
  height: 47px;
}

.venuelatitude {
  display: flex;
  justify-content: space-between;
}

.adminfooterDiv {
  height: 80px !important;
  width: 100%;
  background: #ffff !important;
  /* box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2); */
  border: 0.2px solid #C3C3C3;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: end;
  align-items: center;
}

.nextbtn {
  background: #2C9CF0 !important;
  width: 124px !important;
  font-size: 20px !important;
  font-family: "Poppins";
  height: 45px !important;
  color: #ffff !important;
  margin-right: 19% !important;
}

.basicnextbtn {
  background: #2C9CF0 !important;
  width: 124px !important;
  font-size: 20px !important;
  font-family: "Poppins";
  height: 45px !important;
  color: #ffff !important;
  margin-right: 11.5% !important;
}

.locationnextbtn {
  background: #2C9CF0 !important;
  width: 124px !important;
  font-size: 20px !important;
  font-family: "Poppins";
  height: 45px !important;
  color: #ffff !important;
  margin-right: 11.5% !important;
}

.backbtn {
  background: #6A6A6A !important;
  width: 124px !important;
  height: 45px !important;
  color: #ffff !important;
  margin-right: 10px !important;
}

.backbtn:hover {
  background: #259bf4 !important;
}

.backbtn:hover~.nextbtn {
  background: #6A6A6A !important;
}

.coverImg_style {
  background-color: rgb(244, 247, 248);
  border: 2px dashed rgb(188, 199, 203);
  width: 388px;
  height: 202px;
}

.uploadFile {
  position: absolute;
  width: 388px;
  height: 202px;
}

.selectFileStyle {
  position: relative;
  top: 0;
  Z-index: 1;
  opacity: 0;
}

.poppins16 {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: bold;
}

.poppins12 {
  font-family: "Poppins";
  font-size: 12px;
  color: #676767;
}

.uploadIcon {
  font-size: 50px !important;
  margin-top: 10%;
}

.uploadbtn {
  width: 388px !important;
  background: #2C9CF0 !important;
  color: #ffff !important;
}

.ticket-type-btn {
  width: 45%;
  border-radius: 0.5rem;
  height: 47px;
  border: 1px solid #2C9CF0 !important;
}

.fulllength {
  width: 50%;
}

.primary {
  width: 45%;
  border-radius: 0.5rem;
  background: #2C9CF0 !important;
}

.ticketfooterDiv {
  height: 80px !important;
  background: #FFFFFF !important;
  position: sticky;
  bottom: 0;
  display: flex;
  border: 0.2px solid #C3C3C3;
  justify-content: center;
  align-items: center;
}

.createticketbtn {
  background: #2C9CF0 !important;
  width: 35% !important;
  font-size: 18px !important;
  font-family: "Poppins";
  height: 45.33px !important;
  color: #ffff !important;
  border-radius: 0.8rem !important;
}

@media screen and (max-width: 1115px) {
  .subnavbar {
    width: 60%;
    margin: auto;
    margin-top: 32px;
  }

  .subnavitem {
    font-family: "Poppins";
    font-size: 20px;
    color: #808080;
  }

  .poppins20 {
    font-family: "Poppins";
    font-size: 18px;
  }

  .coverImg_style {
    background-color: rgb(244, 247, 248);
    border: 2px dashed rgb(188, 199, 203);
    width: 368px;
    height: 202px;

  }

  .uploadFile {
    position: absolute;
    width: 368px;
    height: 202px;

  }

  .uploadbtn {
    width: 368px !important;
    background: #2C9CF0 !important;
    color: #ffff !important;
  }
}

@media screen and (max-width: 768px) {
  .subnavbar {
    width: 60%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: -5%;
  }

  .subnavitem {
    font-family: "Poppins";
    font-size: 15px;
    color: #808080;
  }

  .poppins20 {
    font-family: "Poppins";
    font-size: 12px;
  }

  .adminfooterDiv {
    height: 45px !important;
    background: #D9D9D9 !important;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .nextbtn {
    background: #6A6A6A !important;
    width: 104px !important;
    height: 35px !important;
    color: #ffff !important;
    margin-right: 20% !important;
  }

  .backbtn {
    background: #6A6A6A !important;
    width: 104px !important;
    height: 35px !important;
    color: #ffff !important;
    margin-right: 10px !important;
  }

  .eveVisibilitybtn {
    width: 25% !important;
    height: 37px;
  }

  .form-control {
    font-size: 12px !important;
  }

  .coverImg_style {
    background-color: rgb(244, 247, 248);
    border: 2px dashed rgb(188, 199, 203);
    width: 308px;
    height: 195px;

  }

  .uploadFile {
    position: absolute;
    width: 308px;
    height: 195px;

  }

  .uploadbtn {
    width: 308px !important;
    background: #2C9CF0 !important;
    color: #ffff !important;
  }
}

/* common style */
/* hide large screen code at 568px */
@media (max-width: 580px) {
  #largesScreenDevice {
    display: none;
  }

  #SmallScreenDevice {
    display: block;
  }

  /* AddNewEvent Style */
  .subnavbar {
    width: 90%;
    margin: auto;
    margin-top: 18px;
    margin-bottom: -5%;
  }

  .subnavitem {
    font-family: "Poppins";
    font-size: 16px;
    color: #808080;
  }

  .border-line {
    border-width: 0.2px !important;
  }

  .addeventdetailDiv {
    width: 100%;
    margin: auto;
    height: 100vh;
  }

  .poppins20 {
    font-family: "Poppins";
    font-size: 16px;
  }

  .form-control,
  .file-input-label {
    font-size: 14px !important;
    border-color: #2C9CF0 !important;
    height: 40px !important;
  }

  .show {
    font-size: 10px !important;
  }

  .adminfooterDiv {
    height: 80px !important;
    background: #FFFFFF !important;
    position: sticky;
    bottom: 0;
    display: flex;
    border: 0.2px solid #C3C3C3;
    justify-content: end;
    align-items: center;
  }

  .basicnextbtn {
    background: #2C9CF0 !important;
    width: 141px !important;
    font-size: 18px !important;
    font-family: "Poppins";
    height: 45.33px !important;
    color: #ffff !important;
    margin-right: 7% !important;
    border-radius: 0.8rem !important;
  }

  .venuelatitude {
    display: flex;
    flex-direction: column !important;
  }

  .backbtn {
    width: 141px !important;
    font-size: 18px !important;
    font-family: "Poppins";
    height: 45.33px !important;
    color: #ffff !important;
    margin-right: 7% !important;
    background: #6A6A6A !important;
    border-radius: 0.8rem !important;
    display: none !important;
  }

  .des {
    margin-top: 25px !important;
  }

  .fulllength {
    width: 100% !important;
  }

  .ticket-type-btn {
    height: 47px;
    border: 2px solid #2C9CF0 !important;
  }

  .ticketfooterDiv {
    height: 80px !important;
    background: #FFFFFF !important;
    position: fixed;
    width: 100%;
    bottom: 0;
    display: flex;
    border: 0.2px solid #C3C3C3;
    justify-content: center;
    align-items: center;
  }

  .setbottommargin {
    margin-bottom: 15rem !important;
  }

  .createticketbtn {
    background: #2C9CF0 !important;
    width: 50% !important;
    font-size: 18px !important;
    font-family: "Poppins";
    height: 45.33px !important;
    border-radius: 0.8rem !important;
  }

  .poppins10-sm {
    font-size: 14px;
  }

  .poppins14-sm {
    font-size: 14px;
  }

  .poppins16-sm {
    font-size: 16px;
  }

  .addticketbtn {
    border: 1.5px solid #2C9CF0 !important;
    color: #2C9CF0 !important;
    width: 70% !important;
  }

  #small-device-View {
    display: block !important;
  }

  #largedeviceView {
    display: none;
  }

  .addbank-head {
    font-size: 18px !important;
    font-family: "Poppins";
  }

  .addbank-btn {
    background-color: #2C9CF0;
    color: #ffff;
    padding: 0rem !important;
    border-radius: 0.5rem;
    font-family: "Poppins";
    border: none;
    width: 190px;
    height: 60px;
    margin-left: 1rem;
  }
}


/* Css For Admin Part */

/* Style For Admin Part */
/* Admin EventList Page CSS */
.eventHeader {
  background-color: rgba(87, 36, 195, 0.877);
}

.viewbtn {
  border: 1px solid gray;
  border-radius: 20px;
  background-color: rgb(254, 252, 252);
  width: 80px;
}

.eventList {
  width: 98%;
}

.eventList:hover {
  width: 100%;
  box-shadow: 2px 5px 10px rgb(185, 183, 183);
}


.isActiveForm {
  color: rgb(28, 184, 246);
}

.border-line {
  border-top: 2px solid gray;
  margin-top: 12px;
}

.form-control,
.file-input-label {
  border: 2px solid rgb(28, 184, 246);
  height: 48px !important;
}

.activebtn {
  width: 70% !important;
}

.ticketactivebtn {
  width: 80% !important;
  background: #D5FACF;
  color: #30912E;
  padding: 0.2rem 0.7rem;
}

.addticketbtn {
  border: 1.5px solid #2C9CF0 !important;
  color: #2C9CF0 !important;
}

.file-input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.file-input-container input[type="file"] {
  display: none;
}

.file-input-container label.file-input-label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  padding: 0 10px;
  box-sizing: border-box;
}

.file-input-container input[type="text"] {
  width: calc(100% - 40px);
  border: none;
  padding: 0;
  margin: 0;
}

.upload-icon {
  font-size: 20px;
  margin-left: auto;
  margin-right: 10px;
}

.upload-text {
  flex-grow: 1;
}

.file-input-container label.file-input-label:hover {
  border-color: #2C9CF0;
}

.file-input-container label.file-input-label:focus-within {
  border-color: #2C9CF0;
}

.addMore-Btn {
  background: none;
  border: none;
  color: #2C9CF0;
}



/* Common Css For Pages */
.vender-heading {
  font-family: "Poppins";
  font-size: 25px;
  font-weight: bold;
}

.dashboard-summary {
  color: #202224;
  font-family: "Poppins";
  font-size: 14px;
}

.tableHead {
  background-color: #979797 !important;
}

.vendor-event-status-active {
  background: #00B69B;
  color: #FFFFFF;
  padding: 0.3rem 1.3rem;
  border-radius: 1rem;
}

.tableDataRow:hover {
  cursor: pointer;
}

.createnewbtn {
  padding: 0.3rem 1rem 0rem 1rem;
  text-decoration: none;
  background: #2C9CF0;
  border-radius: 10rem;
  font-family: "Poppins";
  color: #ffff;
  margin-bottom: 0.2rem;
}

.addbank-btn {
  background-color: #2C9CF0;
  color: #ffff;
  padding: 0.3rem 1rem 0.3rem 1rem;
  border-radius: 0.5rem;
  font-family: "Poppins";
  border: none;
}

.btn-close-modal {
  background-color: #6A6A6A !important;
  color: #FFFFFF;
  padding: 0.3rem 1rem 0.3rem 1rem;
  border-radius: 0.5rem;
  font-family: "Poppins";
  border: none;
}

.btn-save-modal {
  background-color: #2C9CF0 !important;
  color: #FFFFFF;
  padding: 0.3rem 1rem 0.3rem 1rem;
  border-radius: 0.5rem;
  font-family: "Poppins";
  border: none;
}

.addbank-head {
  font-size: 22px;
  font-family: "Poppins";
}

#small-device-View {
  display: none;
}

.date-title{
  font-size: 32px;
  font-family: "Poppins";
  font-weight: bold;
}

.dateSpanStyle{
  border: 1px solid #2C9CF0;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1%;
  cursor: pointer;
  outline: none;     /* Removes the focus outline */
  user-select: none; /* Prevents text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
}
.selectedDate{
  border: 1px solid #2C9CF0;
  border-radius: 0.5rem;
  color: white;
  background:#2C9CF0;
}

.addticket_btn {
  border: 1.5px solid #2C9CF0 !important;
  background-color: #2C9CF0 !important;
  color: #FFFFFF !important;
  padding: 0.3rem 1rem 0.3rem 1rem;
}

.ticketperday_info{
  display: none;
}
.infoicon:hover .ticketperday_info{
  margin-left: 5px;
  border: 0.5px solid red;
  padding: 0.5px 5px;
display: inline;
font-size: 12px;
}
