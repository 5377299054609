.generate-btn{
    width: 40% !important;
    height: 35px;
    color: #ffff !important;
    border: none;
    border-radius: 0.7rem;
    font-size: 20px;
    padding: 0.2rem 0rem;
}
.generate-btn-disable{
    background: #6A6A6A !important;
}
.generate-btn-enable{
    background: #1f8ce0 !important;
}
.count-style{
    font-size: 28px;
    font-family: "Poppins";
}
.ListofEvents{
    width: 50%;
}

@media screen and (max-width: 768px) {
    .ListofEvents{
        width: 98%;
        margin: auto;
    }
    .hideit_768{
        display: none;
    }
}
