.moveRight{
    margin-left: 15% !important;
}
.poppins30 {
    font-family: "Poppins";
    font-size: 30px;
  }
  .addArrarybtn{
    background: none;
    border: none;
    color: #2C9CF0;
  }



@media screen and (max-width: 568px) {
  .moveRight{
    margin-left: 1% !important;
}
}