.sidebar-list {
    list-style: none;
    margin-bottom: 3%;
    padding: 0.7rem;
    border-radius: 0.5rem;
}

.sidebar-link {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: "Poppins";
}

.sidebar-icon {
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;
}

/* Active tab styling */
.active {
    background-color: #4880FF;
    color: white;
}

.active .sidebar-icon,.active .sidebar-tab {
    color: white; /* Ensures icons are white when active */
}


@media screen  and (max-width: 768px) {
#hideSidebar{
    display: none;
}
.sidebar-tab{
    font-size: 16px;
}
.sidebar-list {
    list-style: none;
    margin-bottom: 3%;
    padding: 0.7rem 0.2rem;
    border-radius: 0.5rem;
}
}