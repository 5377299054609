.login-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  /* Ensure the modal stays on top */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  /* Semi-transparent background */
}

/* .containerr {
  width: 100%;
  margin: auto;
  background-color: none;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  border: 2px solid yellow !important;
} */

.login-container {
  width: 90%;
  height: 426px;
  border-radius: 20px;
  margin: auto;
  background-color: white;
  text-align: center;
  position: relative;
  padding: 8%;
}

.book {
  display: inline-block;
  margin: 0;
  font-size: 21px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.your-fs {
  display: inline-block;
  margin: 0;
  font-size: 21px;
  font-family: "Poppins", sans-serif;
  color: rgba(44, 156, 240, 1);
}

.login {
  text-align: start;
  width: 107px;
  height: 60px;
  font-size: 40px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-bottom: 18px;
  margin-top: 25px;
}

.close {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  /* Circular box */
  position: absolute;
  top: -10px;
  /* Adjust position */
  right: -10px;
  /* Adjust position */
  cursor: pointer;
  padding: 5px;
  /* Adjust padding as needed */
  border: solid 1.5px black;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  /* Ensure it's above other content */
  font-weight: 500;
  font-size: 25px;
  padding-bottom: 5px;
}

.div-num {
  justify-content: start;
}

.enter-number {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: start;
  height: 24px;
  font-weight: 500;
  font-size: 16px;
}

.input-number[type="tel"] {
  width: calc(100% - 40px);
  padding: 10px;
  margin-bottom: 30px;
  border-radius: 9px;
  box-sizing: border-box;
  width: 451px;
  height: 57px;
  border: solid 1.7px rgba(44, 156, 240, 1);
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.getOtpBtn {
  width: 214px;
  height: 45px;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  background-color: rgba(44, 156, 240, 1);
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.resend-otp {
  display: flex;
  justify-content: start;
  margin-top: 20px;
}

.resend-text {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.isResendDisabled {
  color: rgb(122, 186, 235);
}

.isResendEnabled {
  color: rgb(6, 132, 228);
}

.countdown {
  margin-left: 0.3rem;
  font-size: small;
}

.seconds {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  margin-left: 5px;
  font-weight: 500;
}


.login-btn {
  width: 214px;
  height: 45px;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 33px;
  border: none;
  border-radius: 5px;
  background-color: rgba(44, 156, 240, 1);
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.input-otp {
  width: 451px;
  height: 57px;
  border: solid 1.7px rgba(44, 156, 240, 1);
  border-radius: 9px;

}

.closebtn {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  font-size: x-large;
}

.closebtn:hover {
  border: 2px solid gray;
  background-color: navy !important;
  color: white;
}

.btn {
  width: 40%;
  margin: 0rem 1rem;
}

@media screen and (max-width: 980px) {
  .login-container {
    width: 120%;
    height: 406px;
    border-radius: 20px;
    margin: auto;
    margin-left: -10%;
    background-color: white;
    text-align: center;
    position: relative;
    padding: 8%;

  }
}
@media screen and (max-width: 698px){
  .login-container {
    width: 150%;
    height: 406px;
    border-radius: 20px;
    margin: auto;
    margin-left: -20%;
    background-color: white;
    text-align: center;
    position: relative;
    padding: 8%;

  }
}
@media screen and (max-width: 460px){
  .login-container {
    width: 180%;
    height: 406px;
    border-radius: 20px;
    margin: auto;
    margin-left: -40%;
    margin-top: -50%;
    background-color: white;
    text-align: center;
    position: relative;
    padding: 8%;

  }
  .mobileinput{
    height: 50px;
    font-size: 18px !important;
  }
}