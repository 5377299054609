.heading-main{
    color: #2C9CF0 !important;
    font-size: 26px;
    font-family: "Poppins";
}
.eventDetailStyle{
    min-height: 91px;
    border: none !important;
    box-shadow: 2px 2px 2px 2px #cfcdcd;
    margin-top: 20px;
}
.checkboxSize{
    width: 30px;
}
.semi18pop{
    font-size: 18px;
    font-family: "Poppins";
}
.fs-pop-16{
    font-size: 16px;
    font-family: "Poppins";
}
.fs-pop-14{
    color: #6A6A6A;
    font-size: 14px;
    font-family: "Poppins";
}
.fs-pop-12{
    font-size: 12px;
    font-family: "Poppins";
}
.fs-pop-15{
    font-size: 15px;
    font-family: "Poppins";
}
.btn-style{
    background-color: #2C9CF0;
    border: none;
    border-radius: 0.5rem;
    height: 75px;
    width: 80%;
    color: #ffff;
    font-size: 26px;
    font-family: "Poppins";
}

input[type="checkbox"]:checked {
    background-color: initial !important; /* Ensures default blue is applied */
    border-color: initial !important; /* Keeps the default checked border */
  }


/* webcam */
@media screen and (min-width: 560px) {
    .webcamscanner{
        width: 50%;
    }
}