.SuccessMssg{
    width: 36%;
    height: 95%;
    margin: auto;
    margin-top: 1%;
}
.SuccessMssgDiv{
    width: 100%;
    min-height: 75%;
    margin-top: 1%;
    border-radius: 1rem;
}
.textdiv{
    font-family: "Poppins";
    font-size: 12px;
}
.fontSizeSeatBook{
    font-size: 26px;
    font-family: "Poppins";
    font-weight: 500;
    margin-top: 2rem;
}
.viewticketbtn{
    background: #2C9CF0;
    color: #ffff;
    border: none !important;
    border-radius: 0.7rem;
    font-size: 20px;
    font-family: "Poppins";
    padding: 0.5rem 4rem;
    margin-top: 1.5rem;
}
.heading-main{
    color: #2C9CF0 !important;
    font-size: 26px;
    font-family: "Poppins";
}

@media screen and (max-width:680px) {
    .SuccessMssg{
        width: 90%;
        height: 75%;
        margin: auto;
        margin-top: 5%;
    }
}