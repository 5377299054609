.userInfo {
    margin-top: 12px;
}

.lists {
    width: 90%;
}

.Input {
    font-family: "Poppins";
    min-width: 280px;
    height: 34px;
    font-size: 16px;
    border: none !important;
    margin: 0rem 1.5rem !important;
}

.personalDetail {
    margin-bottom: 23px;
}

.fieldname {
    font-size: 20px;
    font-family: "Poppins";
    font-weight: 400;
}

/* Additional Styles */
.form-control {
    width: 100%;
}

.react-datepicker-wrapper {
    width: 100%;
}

.updatebtn{
    width: 214px;
    height: 50px;
    font-size: 20px;
    text-align: center;
    font-family: "Poppins";
    color: #fff;
    background: #2C9CF0;
    border-radius: 0.5rem;
    border: none;
    margin-left: 2%;
}

.view_doc_btn{
    background-color: #F41E6D;
    color: #FFFFFF;
    font-size: 14px;
    font-family: "Poppins";
    padding: 0.2rem 2rem;
    border-radius: 5rem;
    border: none !important;
  }

@media screen and (max-width: 768px){
    .fieldname {
        font-size: 14px;
        font-family: "Poppins";
        font-weight: 400;
    }
    
    .Input {
        font-family: "Poppins";
        min-width: 180px;
        height: 24px;
        font-size: 12px;
        border: none !important;
        margin: 0rem 1rem !important;
    }
}